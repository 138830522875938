<template>
    <v-col cols="12" :md="md" :sm="sm">
        <v-sheet outlined :color="reload ? `orange lighten-4` : null" rounded>
            <emc-card-stats 
                color="orange lighten-1"
                icon="mdi-cash-register"
                icon-large
                :title="title"
                :value="valueFormatted"
                :sub-text="subTitle"
                elevation="0"
                min-height="260"
                >
                    <template v-slot:actions>
                        <v-btn icon @click="$emit('onClickedDelayedExpense')" :disabled="reload"><v-icon color="green">mdi-filter-outline</v-icon></v-btn>
                    </template>
                </emc-card-stats>
            </v-sheet>
    </v-col>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    name: 'CardDelayedExpense',
    data () {
        return {
            title: 'Despesas em atraso',
            subTitle: 'Valor de todas as despesas vencidas e não pagas',
            value: "0,00"
        }
    },
    props: {
        md: { type: [String, Number], default: 3 },
        sm: { type: [String, Number], default: 6 },
        reload: { type: Boolean, required: false }
    },
    computed: {
        valueFormatted() {
            return `R$ ` + this.value
        }
    },
    methods: {
        ...mapActions('finance', ['ActionDelayedExpenseIndex']),
        getData() {
            this.ActionDelayedExpenseIndex()
                .then((r) => {
                    this.value = r.data.posting_value_financial_item
                })
        }
    },
    watch: {
        reload(val) {
            if(val) {
                this.getData()
            }
        }
    }
}
</script>