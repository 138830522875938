<template>
    <div>

        <v-expand-transition>

            <v-row class="mb-3 mt-2" v-show="showDashboard">
                <CardReceiptsMonth 
                    :reload="refreshDashboards || filterReceiptMonth" 
                    @onClickedReceiptsMonth="refreshReceiptMonth"
                    md="3"
                />
                <CardExpensesMonth 
                    :reload="refreshDashboards || filterExpensesMonth" 
                    @onClickedExpensesMonth="refreshExpensesMonth"
                    md="2"
                />
                <CardOverdueIncome 
                    :reload="refreshDashboards || filterOverdueIncome" 
                    @onClickedOverdueIncome="refreshOverdueIncome"
                    md="2"
                />
                <CardDelayedExpense 
                    :reload="refreshDashboards || filterDelayedExpense" 
                    @onClickedDelayedExpense="refreshDelayedExpense"
                    md="2"
                />
                <CardBalanceEntries :reload="refreshDashboards" 
                    md="3"
                />
                
            </v-row>

        </v-expand-transition>

        <main-container :icon="$route.meta.icon" :title="$route.meta.title">

            <emc-table-items
                :headers="headers"
                v-bind:params.sync="params"
                v-bind:collections.sync="collections"
                v-bind:meta.sync="meta"
                v-bind:itemDelete.sync="itemDelete"
                v-bind:itemEdit.sync="model"
                :loading="loading"
            >
            
                <template v-slot:footer.prepend>
                    <v-switch
                        v-model="showAllItems"
                        label="Exibir todos os itens"
                        ></v-switch>
                </template>

                <template v-slot:item.is_receipt="{item}">

                    <v-chip
                        v-if="item.is_receipt"
                        class="ma-2"
                        color="green"
                        label
                        text-color="white"
                        small
                    >
                        <v-icon left small>
                            mdi-arrow-up-bold-circle
                        </v-icon>
                        Receita
                    </v-chip>
                    
                    <v-chip
                        v-else
                        class="ma-2"
                        color="red"
                        label
                        text-color="white"
                        small
                    >
                        <v-icon left small>
                            mdi-arrow-down-bold-circle
                        </v-icon>
                        Despesa
                    </v-chip>

                </template>

                <template v-slot:item.progress="{item}">
                    <apexchart v-if="item.number_end == 1 && item.finished_at" type="radialBar" :options="chartOptions7" :series="[100]" width="80" height="100"></apexchart> 
                    <apexchart v-else type="radialBar" :options="chartOptions7" :series="[Math.floor((item.count_payments / item.number_end) * 100)]" width="80" height="100"></apexchart> 
                </template>
            </emc-table-items>

            <template v-slot:tools>

                <v-btn small color="primary" @click="getData(true)" v-if="filtered && !loading">
                    Remover filtro
                    <v-icon right dark>
                        mdi-filter-remove-outline
                    </v-icon>
                </v-btn>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon @click="showDashboard = !showDashboard" v-bind="attrs" v-on="on">
                            <v-icon :color="showDashboard ? `primary` : null">mdi-monitor-dashboard</v-icon>
                        </v-btn>
                    </template>

                    <span>Visualizar os dados resumidos dos lançamentos financeiros</span>

                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon @click="showGraph = !showGraph" v-bind="attrs" v-on="on">
                            <v-icon>mdi-chart-areaspline</v-icon>
                        </v-btn>
                    </template>

                    <span>Ver a evolução dos lançamentos nos últimos meses</span>

                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon @click="showCashForcast = !showCashForcast" v-bind="attrs" v-on="on">
                            <v-icon>mdi-chart-areaspline-variant</v-icon>
                        </v-btn>
                    </template>

                    <span>Previsão da evolução do caixa</span>

                </v-tooltip>

                <EmcButtonIconCreate
                    @update:show="(value) => showForm = value"
                />
            </template>

            <FormRegistreFinance
                :show="showForm"
                @update:show="(value) => showForm = value"
                :model="model"
                @onCompleted="onSave"
            />

            <GraphItemsFromRecentMonths
                :show="showGraph"
                @update:show="(value) => showGraph = value"
            />

            <GraphCastForecast
                :show="showCashForcast"
                @update:show="(value) => showCashForcast = value"
            />

            <!-- Modal usado para mensagem e ação de exclusão -->
            <emc-alert-modal :text="text" v-bind:show.sync="show">
                <template v-slot:actions>
                    <v-btn color="error" @click.prevent.stop="deleteItem()" :loading="deleting" :disabled="deleting">Excluir <v-icon right dark>mdi-delete</v-icon></v-btn>
                </template>
            </emc-alert-modal>
        </main-container>

    </div>
</template>

<script>
import { mapActions } from 'vuex'
import FormRegistreFinance from './FormRegistreFinance.vue';
import CardReceiptsMonth from '@/modules/management/finance/pages/dashboard/CardReceiptsMonth.vue';
import CardExpensesMonth from '@/modules/management/finance/pages/dashboard/CardExpensesMonth.vue';
import CardOverdueIncome from '@/modules/management/finance/pages/dashboard/CardOverdueIncome.vue'
import CardBalanceEntries from '@/modules/management/finance/pages/dashboard/CardBalanceEntries.vue'
import CardDelayedExpense from '@/modules/management/finance/pages/dashboard/CardDelayedExpense.vue'
import GraphItemsFromRecentMonths from '@/modules/management/finance/components/GraphItemsFromRecentMonths.vue';
import GraphCastForecast from '@/modules/management/finance/components/GraphCashForecast.vue';

export default {
    name: 'InputsIndex',
    data () {
        return {
            series7: [0],
          chartOptions7: {
            color: ['#ccc'],
            chart: {
              type: 'radialBar',
              offsetY: -6,
              sparkline: {
                enabled: true
              }
            },
            plotOptions: {
              radialBar: {
                startAngle: -90,
                endAngle: 90,
                track: {
                  background: '#F5CBA7',
                },
                dataLabels: {
                  name: {
                    show: false
                  },
                  value: {
                    offsetY: -5,
                    fontSize: '10px',
                    // formatter: function (val) {
                    //     return val
                    // }
                  }
                }
              }
            },
            stroke: {
                lineCap: "round",
            },
            colors: ['#27AE60']
          },
            headers: [
                { text: 'Nome', value: 'name', sortable: false},
                { text: 'Categoria', value: 'type.name', sortable: false},
                { text: 'Data', value: 'due_date', sortable: false, type: 'date' },
                { text: 'Valor', value: 'posting_value_financial_item', sortable: false, type: 'money' },
                { text: 'Pago/Recebido', value: 'finished_at', sortable: false, type: 'date' },
                { text: 'Valor Pago/Recebido', value: 'finalized_value_financial_posting_item', sortable: false, type: 'money' },
                { text: 'Tipo de lançamento', value: 'is_receipt'},
                { text: 'Progresso', value: 'progress', sortable: false},
                { text: 'Parcela', value: 'full_numbers', sortable: false, align: 'center' },
                { text: 'Ações', value: 'actions', sortable: false}
            ],
            params: {},
            meta: {},
            collections: [],
            itemDelete: {},
            model: { is_receipt: 1, was_finished: 0, number_start: 1, number_end: 1 },
            loading: false,
            showForm: false,
            show: false,
            deleting: false,
            message: null,
            error: false,
            text: null,
            showAllItems: false,
            refreshDashboards: false,
            filterReceiptMonth: false,
            filterOverdueIncome: false,
            filterExpensesMonth: false,
            filterDelayedExpense: false,
            customFilter: null,
            showGraph: false,
            showDashboard: false,
            showCashForcast: false
        }
    },
    components: {
        FormRegistreFinance,
        CardReceiptsMonth,
        CardExpensesMonth,
        CardOverdueIncome,
        CardBalanceEntries,
        CardDelayedExpense,
        GraphItemsFromRecentMonths,
        GraphCastForecast
    },
    methods: {
        ...mapActions('finance', ['ActionPostingFinancialItemsIndex', 'ActionPostingFinancialItemsDestroy']),
        clearRefresh() {
            this.refreshDashboards = false
            this.filterReceiptMonth = false
            this.filterOverdueIncome = false
            this.filterExpensesMonth = false
            this.filterDelayedExpense = false
            this.customFilter = null
            delete this.params.filter_columns
            delete this.params.filter_relations
        },
        refreshReceiptMonth() {
            this.clearRefresh()
            let today = new Date();
            let firstDay = new Date(today.getFullYear(), today.getMonth(), 1).toISOString();
            const [yearFirstDay, monthFirstDay, dayFirstDay] = firstDay.split('-')
            firstDay = `${yearFirstDay}-${monthFirstDay}-${dayFirstDay.substring(0, 2)}`

            let lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0).toISOString();
            const [yearLasttDay, monthLastDay, dayLastDay] = lastDay.split('-')
            lastDay = `${yearLasttDay}-${monthLastDay}-${dayLastDay.substring(0, 2)}`

            const filter = {
                filter_columns: [
                    { column: 'due_date', value: [firstDay, lastDay], clause: 'whereBetween'}
                ],
                filter_relations: [
                    { name: 'type', key: 'financial_posting_types.is_receipt', value: 1  }
                ]
            }

            this.filterReceiptMonth = true
            this.customFilter = filter;
            this.getData()
            
        },
        refreshOverdueIncome() {
            this.clearRefresh()
            let today = new Date().toISOString();
            const [year, month, day] = today.split('-')
            today = `${year}-${month}-${day.substring(0, 2)}`

            const filter = {
                filter_columns: [
                    { column: 'finished_at', value: 'isNull' },
                    { column: 'due_date', operator: 'less', value: today}
                ],
                filter_relations: [
                    { name: 'type', key: 'financial_posting_types.is_receipt', value: 1 }
                ]
            }
            this.filterOverdueIncome = true
            this.customFilter = filter;
            this.getData()
        },
        refreshDelayedExpense() {
            this.clearRefresh()
            let today = new Date().toISOString();
            const [year, month, day] = today.split('-')
            today = `${year}-${month}-${day.substring(0, 2)}`

            const filter = {
                filter_columns: [
                    { column: 'finished_at', value: 'isNull' },
                    { column: 'due_date', operator: 'less', value: today}
                ],
                filter_relations: [
                    { name: 'type', key: 'financial_posting_types.is_receipt', value: 0 }
                ]
            }
            this.filterDelayedExpense = true
            this.customFilter = filter;
            this.getData()
        },
        refreshExpensesMonth() {
            this.clearRefresh()
            let today = new Date();
            let firstDay = new Date(today.getFullYear(), today.getMonth(), 1).toISOString();
            const [yearFirstDay, monthFirstDay, dayFirstDay] = firstDay.split('-')
            firstDay = `${yearFirstDay}-${monthFirstDay}-${dayFirstDay.substring(0, 2)}`

            let lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0).toISOString();
            const [yearLasttDay, monthLastDay, dayLastDay] = lastDay.split('-')
            lastDay = `${yearLasttDay}-${monthLastDay}-${dayLastDay.substring(0, 2)}`

            const filter = {
                filter_columns: [
                    { column: 'due_date', value: [firstDay, lastDay], clause: 'whereBetween'}
                ],
                filter_relations: [
                    { name: 'type', key: 'financial_posting_types.is_receipt', value: 0  }
                ]
            }
            
            this.filterExpensesMonth = true
            this.customFilter = filter;
            this.getData()
        },
        onSave() {
            this.refreshDashboards = true
            this.getData()
        },
        getData(clearFilter = false) {
            this.showForm = false;
            this.loading = !this.loading
            this.model = { is_receipt: 1, was_finished: 0, number_start: 1, number_end: 1 }

            if(clearFilter) {
                this.clearRefresh()
            }

            const params = this.params;

            Object.assign(params, {
                with: 'type,items',
                column_order: 'due_date'
            });

            if(this.customFilter) {
                Object.assign(params, this.customFilter); 
            } else if(!this.showAllItems) {
                Object.assign(params, {
                    filter_columns: [
                        { column: 'finished_at', value: 'isNull' }
                    ]
                }); 
            } else {
                delete params.filter_columns
            }

            this.ActionPostingFinancialItemsIndex(params)
                .then((r) => {
                    this.collections = r.data
                    this.meta = r.meta
                })
                .finally(() => {
                    this.loading = false
                    if(!this.customFilter) {
                        this.clearRefresh()
                    }
                })
        },
        deleteItem() {
            this.deleting = true;
            this.message = null;
            this.refreshDashboards = true

            this.ActionPostingFinancialItemsDestroy({ id: this.itemDelete.id })
                .then((res) => {
                    this.message = res.message;
                    this.show = false;
                    this.getData();
                })
                .catch((error) =>{
                    this.message = error.message;
                    this.error = true;
                })
                .finally(() => {
                    this.deleting = false;
                });
        }
    },
    watch: {
        showAllItems() {
            this.getData()
        },
        showForm(val) {
            if(!val) {
                this.model = { is_receipt: 1, was_finished: 0, number_start: 1, number_end: 1 }
            }
        },
        params(params_new, params_old){
            if(params_new != params_old) {
                this.getData()
            }
        },
        model(item_new, item_old){
            if(item_new !== item_old && (this.model && this.model.id))
            {
                this.showForm = true
                this.message = null

            } else if(Object.keys(this.model).length === 0){
                this.message = null
                this.showForm = false;
            }
        },
        itemDelete(item_new){
            
            if(Object.keys(item_new).length > 0)
            {
                this.show = true

                if(!item_new.cluster_key) {
                    this.text = "Deseja excluir " + item_new.name + "?"
                } else {
                    this.text = "Este lançamento financeiro possui vinculos com outros, pois é recorrente. Deseja excluir esse e os lançamentos futuros vinculados a ele?"
                }
                
            } else {
                this.show = false
                this.text = null
            }
        }
    },
    computed: {
        filtered() {
            return this.filterReceiptMonth || this.filterOverdueIncome || this.filterExpensesMonth || this.filterDelayedExpense
        }
    },
    mounted() {
        this.refreshDashboards = true
    }
}
</script>