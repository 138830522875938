<template>
    <sys-register-base
      :loading="loading"
      @onSave="optionSave"
      :model.sync="item"
      :title="title"
      :icon="item.is_receipt ? `mdi-account-cash-outline` : `mdi-cash-register`"
      v-bind:show.sync="dialog"
      maxWidth="50%"
      :color="color"
      height="70"
  >
    <template v-slot:form>

        <v-row>
            <v-col cols="12" v-if="message">
                    <v-alert
                        outlined
                        color="warning"
                        prominent
                        border="left"
                        >
                        {{ message }}
                        </v-alert>
                </v-col>
        </v-row>
        <v-row justify="center" align="center">
            <v-col cols="auto">
                <v-btn-toggle
                    v-model="item.is_receipt"
                    :color="color"
                >
                    <v-btn :value="1">
                    Entrada
                    </v-btn>

                    <v-btn :value="0" class="px-6">
                    Saída
                    </v-btn>
                </v-btn-toggle>
          </v-col>
        </v-row>
        <v-row>
            <EmcFormTextField 
                v-model="item.name" 
                label="Título*" 
                md="6"
                outlined
                :error="errors.length > 0"
                :error-messages="errors.name"
            />

            <SelectFinancialPostingType 
                v-model="item.financial_posting_type_id"
                md="6"
                outlined
                label="Categoria*"
                v-bind:isReceipt.sync="item.is_receipt"
                :error="errors.length > 0"
                :error-messages="errors.financial_posting_type_id"
            />

            <EmcFormDateTextField 
                v-model="item.due_date" 
                :label="item.is_receipt ? `Data da entrada*` : `Data da saída*`" 
                md="6"
                outlined
                :color="color"
                :error="errors.length > 0"
                :error-messages="errors.due_date"
            />

            <EmcFormDecimalTextField 
                v-model="item.posting_value_financial_item"
                label="Valor*"
                outlined
                md="6"
                :error="errors.length > 0"
                :error-messages="errors.posting_value_financial_item"
            />

            <EmcFormTextArea
                v-model="item.description"
                label="Observação"
                outlined
                md="12"
                :error="errors.length > 0"
                :error-messages="errors.description"
            />

            <v-col cols="12" sm="4" :md="item.is_repeat ? 6 : 12" v-if="!item.id && !item.cluster_key">
                <v-sheet class="px-2">
                    <v-switch
                        v-model="item.is_repeat"
                        inset
                        label="Repetir"
                    ></v-switch>
                </v-sheet>
            </v-col>

            <EmcFormTextField 
                v-model="item.number_end" 
                label="Parcelas" 
                md="6"
                outlined
                type="Number"
                v-if="item.is_repeat"
                :error="errors.length > 0"
                :error-messages="errors.number_end"
            />

            <v-col cols="12" md="12">
                <v-sheet class="px-2" v-if="!item.is_repeat">
                    <v-switch
                        v-model="item.was_finished"
                        inset
                        :label="item.is_receipt ? `Recebido` : `Pago`"
                        :color="color"
                    ></v-switch>
                </v-sheet>
            </v-col>

            <EmcFormDateTextField 
                v-model="item.finished_at" 
                :label="item.is_receipt ? `Recebido em` : `Pago em`" 
                md="6"
                outlined
                :color="color"
                v-if="item.was_finished && !item.is_repeat"
                :error="errors.length > 0"
                :error-messages="errors.finished_at"
            />

            <EmcFormDecimalTextField 
                v-model="item.finalized_value_financial_posting_item"
                :label="item.is_receipt ? `Valor recebido` : `Valor pago`" 
                outlined
                md="6"
                v-if="item.was_finished && !item.is_repeat"
                :error="errors.length > 0"
                :error-messages="errors.finalized_value_financial_posting_item"
            />

            <v-col cols="12" v-if="item.cluster_key">
                <v-list>
                    <v-subheader inset>Lançamentos relacionados</v-subheader>

                    <v-list-item
                        v-for="(lancamento, i) in item.items"
                        :key="i"
                        :class="lancamento.id === item.id ? `grey lighten-2` : null"
                    >
                        <v-list-item-avatar>
                        <v-icon
                            :class="item.type.color"
                            dark
                            v-text="item.type.icon"
                        ></v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                        <v-list-item-title v-text="lancamento.name"></v-list-item-title>

                        <v-list-item-subtitle v-text="lancamento.full_numbers"></v-list-item-subtitle>
                        <v-list-item-subtitle v-if="lancamento.finalized_value_financial_posting_item">
                            <v-chip
                                class="ma-2"
                                color="success"
                                outlined
                                small
                            >
                                <v-icon x-small left>
                                    mdi-cash
                                </v-icon>
                                {{ lancamento.finalized_value_financial_posting_item | cash }}
                            </v-chip>

                            <v-chip
                                class="ma-2"
                                
                                outlined
                                small
                            >
                                <v-icon x-small left>
                                    mdi-calendar-today
                                </v-icon>
                                {{ lancamento.finished_at | datetime }}
                            </v-chip>
                        </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                        <v-btn icon>
                            <v-tooltip bottom v-if="lancamento.value_is_late">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="orange" v-bind="attrs" v-on="on">mdi-alert-circle</v-icon>
                                </template>
                                <span>Valor em atraso</span>
                            </v-tooltip>
                            <v-icon v-if="lancamento.was_finished" color="green">mdi-check-circle</v-icon>
                        </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                    </v-list>
            </v-col>
        </v-row>

        <OptionsModalUpdateItem 
            :show="showOptions"
            @update:show="(value) => showOptions = value"
            @onChanged="save"
        />
    </template>
    </sys-register-base>
</template>

<script>

import { mapActions } from 'vuex'
import SelectFinancialPostingType from '@/modules/management/finance/pages/type/SelectFinancialPostingType.vue'
import OptionsModalUpdateItem from '../../components/OptionsModalUpdateItem.vue'

export default {
    name: 'FormRegisterFinance',
    data () {
        return {
            loading: false,
            message: null,
            errors: {},
            showOptions: false
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        model: Object,
    },
    filters: {
        datetime: function (value) {
            if (!value) return ''
            
            const partesData = value.split('-');
            const dia = partesData[2];
            const mes = partesData[1];
            const ano = partesData[0];
            return `${dia}/${mes}/${ano}`;
        },
        cash: function(value) {
            if (!value) return ''

            const number = parseFloat(value)
            const cash = number.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
            return cash
        }
    },
    computed: {
        dialog: {
            get() {
                return this.show
            },
            set(value) {
                this.$emit('update:show', value)
            }
        },
        color() {
            return this.model.is_receipt ? `green lighten-2` : `orange lighten-2`
        },
        item: {
            get() {
                return this.model
            },
            set(val) {
                this.$emit('model', val)
            }
        },
        title() {
            const type  = this.item.is_receipt ? `receita` : `despesa`
            const label = !this.item.id ? `Cadastrar` : `Atualizar`
            return  label + ` ` + type
        }
    },
    watch: {
        'item.was_finished' : function(newVal, oldVal) {
            if(!newVal && oldVal) {
                this.item.finished_at = null
                this.finalized_value_financial_posting_item = null
            } else if(newVal) {
                if(!this.item.finished_at && this.item.due_date) {
                    this.item.finished_at = this.item.due_date
                }

                if(!this.item.finalized_value_financial_posting_item && this.item.posting_value_financial_item) {
                    this.item.finalized_value_financial_posting_item = this.item.posting_value_financial_item
                }
            }
        },
        'item.is_receipt' : function(val) {
            if(this.item.type) {
                if(this.item.type.is_receipt != val) {
                    this.item.financial_posting_type_id = null    
                }
            } else {
                this.item.financial_posting_type_id = null
            }
        }
    },
    methods: {
        ...mapActions('finance', ['ActionPostingFinancialItemsStore', 'ActionPostingFinancialItemsUpdate']),
        initVariables() {
            this.loading = !this.loading
            this.message = null
            this.errors = {}
            this.showOptions = false
        },
        optionSave(edit) {

            if(edit) {

                if(!this.item.was_finished && this.item.cluster_key) {

                    this.showOptions = true

                } else {

                    this.save("1")
                }

            } else {
                this.save(edit)
            }
        },
        save(option) {

            this.initVariables()

            if(option) {

                //Este parâmetro será obrigatório
                this.item.option_update = option;

                this.ActionPostingFinancialItemsUpdate(this.item)
                    .then((res) => {
                        this.message = null;
                        this.$emit('onCompleted', res.data);
                    })
                    .catch((error) => {
                        this.errors     = error.errors;
                        this.message    = error.message;
                    })
                    .finally(() => {
                        this.loading = !this.loading
                    })
            } else {
                this.ActionPostingFinancialItemsStore(this.item)
                    .then((res) => {
                        this.message = null;
                        this.$emit('onCompleted', res.data);
                    })
                    .catch((error) => {
                        this.errors     = error.errors;
                        this.message    = error.message;
                    })
                    .finally(() => {
                        this.loading = !this.loading
                    })
            }
        }
    },
    components: {
        SelectFinancialPostingType,
        OptionsModalUpdateItem
    }
}
</script>