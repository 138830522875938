<template>
    <v-col :cols="cols" :md="md">
            <v-autocomplete
              v-model="model"
              :items="items"
              :disabled="loading || disabled"
              outlined
              
              :label="label"
              item-text="name"
              item-value="id"
              :hide-details="hideDetails"
              :loading="loading"
              :error="error"
              :error-messages="errorMessages"
            >
              <!-- <template v-slot:selection="data">
                <v-chip
                  small
                    v-bind="data.attrs"
                    :input-value="data.selected"
                >
                <v-avatar left>
                    <v-icon>{{ data.item.icon }}</v-icon>
                </v-avatar>
                {{ data.item.name }}
                </v-chip>
              </template> -->
              <template v-slot:item="data">
                  <v-list-item-icon>
                    <v-icon>{{ data.item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                  </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
</template>

<script>
  import { mapActions } from 'vuex'
  export default {
    name: 'SelectFinancialPostingType',
    props: {
        cols: { type: [String, Number], default: 12 },
        md: { type: [String, Number], default: 6 },
        outlined: { type: Boolean, required: false },
        label: { type: String, required: false },
        value: { type: [String, Number, Object] },
        hideDetails: { type: [Boolean, String], default: 'auto' },
        isReceipt: { type: [Boolean, Number], required: false },
        disabled: { type: Boolean, required: false },
        errorMessages: { type: Object, required: false },
        error: { type: Boolean, required: false }
    },
    data () {
      return {
        loading: false,
        items: []
      }
    },
    computed: {
      model: {
        get() {
          return this.value
        },
        set(val) {
          this.$emit('input', val)
        }
      },
      receipt: function() {
          return this.isReceipt
      }
    },
    watch: {
      isReceipt(newVal, oldVal) {
        if(newVal != oldVal) {
          this.getData()
        }
      }
    },
    methods: {
      ...mapActions('finance', ['ActionFinancialPostingTypesIndex']),
      getData() {
        this.loading = true
        const params = {
          find_columns: { is_receipt: this.receipt ? 1 : 0 }
        }
        this.ActionFinancialPostingTypesIndex(params)
                .then((r) => {
                    this.items = r.data
                })
                .finally(() => {
                    this.loading = false
                })
      }
    },
    created() {
      this.getData()
    }
  }
</script>