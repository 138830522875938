<template>
    <v-col cols="12" :md="md" :sm="sm">
        <v-sheet outlined :color="reload ? `green lighten-4` : null" rounded>
            <emc-card-stats 
            color="green darken-2"
            icon="mdi-account-cash-outline"
            icon-large
            :title="title"
            :value="valueFormatted"
            :sub-text="subTitle"
            elevation="0"
            min-height="260"
            >
                <template v-slot:actions>
                    <v-btn icon @click="$emit('onClickedReceiptsMonth')" :disabled="reload"><v-icon color="green">mdi-filter-outline</v-icon></v-btn>
                </template>
            </emc-card-stats>
        </v-sheet>
    </v-col>
</template>

<script>

import { mapActions } from 'vuex'

export default {
    name: 'CardReceiptsMonth',
    data () {
        return {
            title: 'No mês',
            subTitle: 'Valor previsto para receita no mês',
            value: "0,00"
        }
    },
    props: {
        md: { type: [String, Number], default: 3 },
        sm: { type: [String, Number], default: 6 },
        reload: { type: Boolean, required: false }
    
    },
    computed: {
        valueFormatted() {
            return `R$ ` + this.value
        }
    },
    methods: {
        ...mapActions('finance', ['ActionForecastMonthIndex']),
        getData() {
            this.ActionForecastMonthIndex()
                .then((r) => {
                    this.value = r.data.posting_value_financial_item
                })
        }
    },
    watch: {
        reload(val) {
            if(val) {
                this.getData()
            }
        }
    }

}
</script>