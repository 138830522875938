<template>
    <emc-dialog-default 
        :show="dialog" 
        @update:show="(value) => dialog = value"
        title="Transações finalizadas nos ultimos 8 meses"
        max-width="80%"
        icon="mdi-chart-areaspline"
    >
        <template v-slot:text>
            <v-row class="mb-3 mt-2">
                <ReleaseChartFromRecentMonths />
            </v-row>
        </template>
    </emc-dialog-default>
</template>

<script>

import ReleaseChartFromRecentMonths from '@/modules/management/finance/pages/dashboard/ReleaseChartFromRecentMonths.vue';

export default {
    props: { 
        show: { type: Boolean, default: false }
    },
    computed: {
        dialog: {
            get() {
                return this.show
            },
            set(val) {
                this.$emit('update:show', val)
            }
        }
    },
    components: {
        ReleaseChartFromRecentMonths
    }
}
</script>