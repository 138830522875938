<template>
    <v-col cols="12" :md="md" :sm="sm">
        <v-sheet outlined rounded>
            <EmcCardStats 
                color="green lighten-1"
                icon="mdi-bank"
                icon-large
                :title="title"
                :value="valueFormatted"
                :sub-text="subTitle"
                elevation="0"
                min-height="260"
                />
        </v-sheet>
    </v-col>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    name: 'CardReceiptsMonth',
    data () {
        return {
            title: 'Saldo no caixa',
            subTitle: 'Saldo considerando valores recebidos e despesas pagas',
            value: "0,00"
        }
    },
    props: {
        md: { type: [String, Number], default: 3 },
        sm: { type: [String, Number], default: 6 },
        reload: { type: Boolean, required: false }
    },
    computed: {
        valueFormatted() {
            return `R$ ` + this.value
        }
    },
    methods: {
        ...mapActions('finance', ['ActionBalanceEntriesIndex']),
        getData() {
            this.ActionBalanceEntriesIndex()
                .then((r) => {
                    this.value = r.data.posting_value_financial_item
                })
        }
    },
    watch: {
        reload(val) {
            if(val) {
                this.getData()
            }
        }
    }
}
</script>