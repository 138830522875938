<template>
    <emc-dialog-default 
        :show="dialog" 
        @update:show="(value) => dialog = value"
        title="Previsão de receita dos próximos meses"
        max-width="80%"
        icon="mdi-chart-areaspline-variant"
    >
        <template v-slot:text>
            <v-row class="mb-3 mt-2">
                <ChartGraphCashForecast />
            </v-row>
        </template>
    </emc-dialog-default>
</template>

<script>

import ChartGraphCashForecast from '@/modules/management/finance/pages/dashboard/ChartGraphCashForecast.vue';

export default {
    props: { 
        show: { type: Boolean, default: false }
    },
    computed: {
        dialog: {
            get() {
                return this.show
            },
            set(val) {
                this.$emit('update:show', val)
            }
        }
    },
    components: {
        ChartGraphCashForecast
    }
}
</script>