<template>
    <v-col cols="12">
        <apexchart height="250" type="area" :options="chartOptions" :series="series"></apexchart>
    </v-col>
</template>

<script>

import { mapActions } from 'vuex'

export default {   
    name: 'EmcChartApexArea',
    data () {
        return {
            chartOptions: {
                chart: {
                    id: 'release-chart',
                    toolbar: false
                },
                xaxis: {
                    categories: ['Jan-23', 'Fev-23', 'Mar-23', 'Abr-23', 'Mai-23', 'Jun-23', 'Jul-23', 'Ago-23', 'Set-23', 'Out-23', 'Nov-23', 'Dez-23'],
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                        }
                    },
                },
                colors:['#E91E63', '#27AE60'],
                dataLabels: {
                    formatter: function (value) {
                        return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                    }
                }
            },
            series: [
                {
                    name: 'Despesa',
                    data: []
                },
                {
                    name: 'Receita',
                    data: []
                },
            ],
        }
    },
    methods: {
        ...mapActions('finance', ['ActionReleaseFromRecentMonthsIndex']),
        getData() {
            this.ActionReleaseFromRecentMonthsIndex()
                .then((r) => {
                    this.series = [
                        { name: 'Despesa', data: r.data.expenses},
                        { name: 'Receita', data: r.data.receipts},
                    ]
                })
        },
        getLast12Months() {
            var months = []; // Array para armazenar os meses

            // Obtém a data atual
            var currentDate = new Date();
            var month;
            var year;

            // Loop para retroceder 12 meses
            for (var i = 0; i < 8; i++) {
                // Adiciona o mês atual ao array
                month = currentDate.toLocaleString('default', { month: 'short' }) 
                year = currentDate.getFullYear()
                months.push(month + " " + year);

                // Retrocede um mês
                currentDate.setMonth(currentDate.getMonth() - 1);
            }

            // Inverte a ordem dos meses para que os mais recentes fiquem primeiro
            months.reverse();

            this.chartOptions.xaxis.categories = months;

            return months;
        }
    },
    created() {
        this.getData();
        this.getLast12Months();
    }
}
</script>