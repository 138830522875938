<template>
    <sys-register-base
      :model="option"
      title="Opções de atualização"
      v-bind:show.sync="dialog"
      maxWidth="30%"
      height="70"
      icon="mdi-refresh-circle"
      :showButton="false"
  >
    <template v-slot:form>
        <v-radio-group v-model="option">
            <v-radio label="Atualizar somente esse item" value="1"></v-radio>
            <v-radio label="Atualizar esse item e os próximos" value="2"></v-radio>
            <v-radio label="Atualizar todos os itens" value="3"></v-radio>
        </v-radio-group>
    </template>

    <template v-slot:actions>
        <v-btn color="primary" @click.prevent.stop="changed()">Atualizar<v-icon right dark>mdi-content-save</v-icon></v-btn>
    </template>

    </sys-register-base>

</template>

<script>
export default {
    name: "OptionsModalUpdateItem",
    data () {
        return {
            option: "1"
        }
    },
    computed: {
        dialog: {
            get() {
                return this.show
            },
            set(val) {
                this.$emit('update:show', val)
            }
        }
    },
    props: {
        show: { type: Boolean, default: false}
    },
    methods: {
        changed() {
            this.$emit('onChanged', this.option);
            this.option = "1"
        }
    }
}
</script>